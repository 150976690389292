/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import Sidebar from "~/@components/SidebarV2";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../lib/hooks";
import { loadConfig, loadUserProfile } from "../lib/app/platform/thunk";
import { getOrganization, listOrganizations } from "../lib/app/organization/thunk";
import { loadMetrics } from "../lib/app/home/thunk";
import moment from "moment-timezone";
import { CommandDialog, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator } from "~/components/ui/command";
import { BlocksIcon, Settings2Icon, Users2Icon } from "lucide-react";
import mixpanel from "mixpanel-browser";

export default function Shell () {
    const dispatch = useAppDispatch()
    const { organization } = useParams()
    const _organization = useAppSelector(state => state.app.organizations.get)
    const organizations = useAppSelector(state => state.app.organizations.list)
    const profile = useAppSelector(state => state.app.platform.profile)
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose, onToggle } = useDisclosure()

    useEffect(() => {
        if (profile.state === "success" && profile.data.id) {
            dispatch(listOrganizations(profile.data.id))
        }
    }, [ profile ])

    useEffect(() => {
        if (organizations.state === "error") {
            navigate('/welcome')   
        }
    }, [ organizations ])
    
    useEffect(() => {
        if (profile.data.id) {
            mixpanel.identify(profile.data.id)
            mixpanel.people.set({
                $name: profile.data.name,
                $email: profile.data.email,
                $avatar: profile.data.profile_pic,
                $created: profile.data.created_at
            })
        }
    }, [profile])

    useEffect(() => {
        const down = (e: KeyboardEvent) => {
            
           if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
              e.preventDefault()
              onToggle()
           }
        }
   
        document.addEventListener('keydown', down)
        return () => document.removeEventListener('keydown', down)
     }, [])
    
    return (
        <Flex>
            <Box w={'240px'}>
                <Sidebar />
            </Box>
            <Box w={"full"} h={"100vh"} overflow={"scroll"} flex={1}>
                <Outlet />
            </Box>
        </Flex>
    )
}