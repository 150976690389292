import { axios } from "../../../utils/axios.loader";
import { AppDispatch } from "../../store";
import { setStoreCreateError, setStoreCreateLoading, setStoreCreateSuccess, setStoreDeleteLoading, setStoreDeleteSuccess, setStoreGetLoading, setStoreGetSuccess, setStoreListLoading, setStoreListSuccess, setStoreUpdateLoading, setStoreUpdateSuccess } from "./actions";

export const getStore = (organization: string, keyword: string = '', {
    page = 1,
    size = 10
}) => async (dispatch: AppDispatch) => {
    dispatch(setStoreListLoading())
    try {
        const request = await axios.get(`/organization/${organization}/stores?page=${page}&page_size=${size}${keyword ? `&filters=name[contains]=${keyword}` : ''}`)
        const { stores, total_count } = request.data?.result || {}
        dispatch(setStoreListSuccess(stores, {
            total_count
        }))
    } catch (err) {}
}

export const getSpecificStore = (organization: string, store: string) => async (dispatch: AppDispatch) => {
    dispatch(setStoreGetLoading())
    try {
        const request = await axios.get(`/organization/${organization}/stores/${store}`)
        const _store = request.data?.result || {}
        dispatch(setStoreGetSuccess(_store))
    } catch (err) {}
}

export const addStore = (organization: string, payload: any, callback?: (data?: any) => void, onerror?:() => void) => async (dispatch: AppDispatch) => {
    dispatch(setStoreCreateLoading())
    try {
        const request = await axios.post(`/organization/${organization}/stores`, payload)
        const response = request.data
        dispatch(setStoreCreateSuccess(response))
        callback?.(response)
    } catch (err) {
        dispatch(setStoreCreateError("Store already active"))
        onerror?.()
    }
}

export const updateStore = (organization: string, store: string, payload: any, callback?: (data: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setStoreUpdateLoading())
    try {
        const request = await axios.patch(`/organization/${organization}/stores/${store}`, payload)
        const response = request.data
        dispatch(setStoreUpdateSuccess(response))
        callback?.(response)
    } catch (err) {}
}


export const removeStore = (organization: string, store: string, callback?: (data: any) => void) => async (dispatch: AppDispatch) => {
    dispatch(setStoreDeleteLoading())
    try {
        const request = await axios.delete(`/organization/${organization}/stores/${store}`)
        const response = request.data
        dispatch(setStoreDeleteSuccess(response))
        callback?.(response)
    } catch (err) {}
}