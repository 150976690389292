export const SET_STORE_CREATE_LOADING = "store/create/loading";
export const SET_STORE_CREATE_SUCCESS = "store/create/success";
export const SET_STORE_CREATE_ERROR = "store/create/error";

export const SET_STORE_LIST_LOADING = "store/list/loading";
export const SET_STORE_LIST_SUCCESS = "store/list/success";
export const SET_STORE_LIST_ERROR = "store/list/error";

export const SET_STORE_UPDATE_LOADING = "store/update/loading";
export const SET_STORE_UPDATE_SUCCESS = "store/update/success";
export const SET_STORE_UPDATE_ERROR = "store/update/error";

export const SET_STORE_DELETE_LOADING = "store/delete/loading";
export const SET_STORE_DELETE_SUCCESS = "store/delete/success";
export const SET_STORE_DELETE_ERROR = "store/delete/error";

export const SET_STORE_GET_LOADING = "store/get/loading";
export const SET_STORE_GET_SUCCESS = "store/get/success";
export const SET_STORE_GET_ERROR = "store/get/error";
