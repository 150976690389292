import {
    Avatar,
    Box,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text,
    Button as CoreButton,
    useDisclosure,
    useToast,
    Link,
  } from "@chakra-ui/react";
  import Page from "../../../@components/Page";
  import {
    ChevronsUpDownIcon,
    Edit2Icon,
    EditIcon,
    MoreVerticalIcon,
    PlusIcon,
    Trash2Icon,
  } from "lucide-react";
  import { Field, Form, Formik } from "formik";
  import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
  import { useEffect, useState } from "react";
  import {
    getStore,
    removeStore,
  } from "../../../lib/app/store/thunk";
  import _ from "lodash";
  import { Select, chakraComponents } from "chakra-react-select";
  import { Button } from "~/components/ui/button";
  import { Badge } from "~/components/ui/badge";
  import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
  } from "~/components/ui/dropdown-menu";
  import ReactWordcloud from "react-wordcloud";
import { useNavigate } from "react-router-dom";
  
  const dropdownStyles: any = {
    dropdownIndicator: (b: any) => ({
      ...b,
      backgroundColor: "transparent",
      borderColor: "transparent",
      border: 0,
    }),
    control: (b: any) => ({
      ...b,
      borderRadius: 8,
    }),
    menuList: (b: any) => ({
      ...b,
      borderRadius: 8,
    }),
  };
  
  const dropdownComponents = {
    DropdownIndicator: (props: any) => (
      <chakraComponents.DropdownIndicator {...props}>
        <ChevronsUpDownIcon size={12} />
      </chakraComponents.DropdownIndicator>
    ),
  };
  
  export default function Store() {
    const _organization = useAppSelector((state) => state.app.organizations.get);
    const _organizationUpdated = useAppSelector(
      (state) => state.app.organizations.update
    );
    const profile = useAppSelector((state) => state.app.platform.profile);
    const store = useAppSelector((state) => state.app.store.list);
    const create = useAppSelector((state) => state.app.store.create);
    const update = useAppSelector((state) => state.app.store.update);
    const _delete = useAppSelector((state) => state.app.store.delete);
    const dispatch = useAppDispatch();
    const toast = useToast();
    const [currentStore, setCurrentStore] = useState<any | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: isDeleteOpen,
      onOpen: onDeleteOpen,
      onClose: onDeleteClose,
    } = useDisclosure();
    const [keyword, setKeyword] = useState('')
    const [page, setPage] = useState(1)
    const [size, setSize] = useState(25)
    const navigate = useNavigate()
  
    const debouncedSearch = _.debounce(() => {
      dispatch(getStore(_organization.data.id, keyword, {
        page,
        size
      }))
    }, 300)
  
    useEffect(() => {
      if (_organization.data.id) {
        debouncedSearch()
      }
    }, [_organization.data, page, size, keyword]);
  
    return (
      <Page
        subtitle="Manage your shopify stores"
        title="Stores"
      >
        <Modal
          isCentered
          isOpen={isDeleteOpen}
          onClose={() => {
            setCurrentStore(null);
            onDeleteClose();
          }}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Remove Store</ModalHeader>
            <ModalBody>
              Are you sure to remove <strong>{currentStore?.name}</strong> from
              your organization ?
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                   dispatch(
                    removeStore(
                        _organization?.data?.id,
                        currentStore?.id,
                        () => {
                        onDeleteClose();
                        setCurrentStore(null);
                        toast({
                            title: "Store removed successfully",
                            status: "success",
                        });
                        setPage(1)
                        setKeyword('')
                        debouncedSearch()
                        }
                    )
                    );
                }}
                isLoading={_delete.state === "loading"}
                variant="destructive"
              >
                Yes, remove
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <Modal
          isCentered
          isOpen={isOpen}
          onClose={() => {
            setCurrentStore(null);
            onClose();
          }}
        >
          <ModalOverlay />
          <ModalContent pb={4}>
            <ModalHeader pb={0}>
              {currentStore?.id ? `Update ${currentStore?.name}` : "Add Store"}
            </ModalHeader>
            <ModalBody>
              <Formik
                initialValues={{
                  email: currentStore?.email || "",
                  name: currentStore?.name || "",
                  role: currentStore?.role || "MEMBER",
                }}
                onSubmit={(values) => {
                //   if (currentStore?.id) {
                //     dispatch(
                      
                //     );
                //   } else {
                //     dispatch(
                     
                //     );
                //   }
                }}
              >
                {({ errors, touched, values, setFieldValue }) => {
                  return (
                    <Form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {!currentStore?.id ? (
                        <Stack gap={3}>
                        <FormControl isInvalid={!!errors.name}>
                          <FormLabel fontSize={"sm"}>Name</FormLabel>
                          <Field
                            fontSize={"sm"}
                            placeholder={"Enter your store's name"}
                            validate={(v: string) => {
                              let error;
                              if (!v) {
                                error = "Store's name is required";
                              }
                              return error;
                            }}
                            as={Input}
                            name="name"
                          />
                          <FormErrorMessage>
                            {errors.name as string}
                          </FormErrorMessage>
                        </FormControl>
                        <FormControl isInvalid={!!errors.email}>
                          <FormLabel fontSize={"sm"}>Email address</FormLabel>
                          <Field
                            fontSize={"sm"}
                            placeholder={"Enter your store's email"}
                            validate={(v: string) => {
                              let error;
                              if (!v) {
                                error = "Store's email is required";
                              }
                              return error;
                            }}
                            as={Input}
                            name="email"
                          />
                          <FormErrorMessage>
                            {errors.email as string}
                          </FormErrorMessage>
                        </FormControl>
                        </Stack>
                      ) : (
                        <></>
                      )}
                      <FormControl mt={3} isInvalid={!!errors.role}>
                        <FormLabel fontSize={"sm"}>Choose Role</FormLabel>
                        <Select
                          value={{
                            label: _.capitalize(values.role),
                            value: values.role,
                          }}
                          placeholder={"Select background sound"}
                          selectedOptionColorScheme="primary"
                          size={"sm"}
                          onChange={(v: any) => {
                            setFieldValue?.("role", v.value);
                          }}
                          variant={"outline"}
                          chakraStyles={dropdownStyles}
                          components={dropdownComponents}
                          options={[
                            {
                              label: "Admin",
                              value: "ADMIN",
                            },
                            {
                              label: "Store",
                              value: "MEMBER",
                            },
                          ]}
                        />
                        <FormErrorMessage>
                          {errors.role as string}
                        </FormErrorMessage>
                      </FormControl>
                      <Button
                        type="submit"
                        onClick={() => {}}
                        isLoading={
                          create.state === "loading" || update.state === "loading"
                        }
                        className="mt-4 w-fit self-end"
                        variant="default"
                      >
                        {currentStore?.id ? "Update Store" : "Add Store"}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            </ModalBody>
          </ModalContent>
        </Modal>
        <Stack px={3} mt={4}>
          <Flex
            mx={2}
            my={4}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>
             {store.state === "success" ? <Text fontWeight={600} fontSize={"semibold"}>Showing {store.metadata?.total_count > 10 ? "10" : store.metadata?.total_count} of {store.metadata?.total_count}</Text> : <></>}
            </Box>
            <Flex gap={2} alignItems={"center"}>
              <FormControl  w={300}>
                <Input
                  borderRadius={12}
                  fontSize={"sm"}
                  fontWeight={"medium"}
                  onChange={(v) => {
                    setKeyword(v.target.value)
                  }}
                  background={"#fff"}
                  placeholder="Search for stores"
                />
              </FormControl>
              {/* <Button
                isLoading={store.state === "loading"}
                onClick={() => {
                  onOpen();
                }}
                variant="dark"
                size={"sm"}
              >
                <PlusIcon size={16} />
                Invite Store
              </Button> */}
            </Flex>
          </Flex>
          <Stack className="border" gap={0} rounded={"xl"}>
            {store.data.map((store: any) => {
              return (
                <Flex
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  px={3}
                  py={2}
                  className="border-b last:border-b-0"
                >
                  <Flex gap={3} alignItems={"center"}>
                    <Avatar
                      size={"sm"}
                      src={
                        store.primary_shop_url ? `https://s2.googleusercontent.com/s2/favicons?domain=${store.primary_shop_url}&sz=256` :
                        `https://avatar.vercel.sh/${store.username}`
                      }
                      
                      name={store.name}
                    />
                    <Box>
                      <Flex gap={2} alignItems={"center"}>
                        <Text fontWeight={"semibold"}>{store.name}</Text>
                        <Badge
                          variant={
                            store.data_sync_status === "SUCCESS"
                              ? "primary-subtle"
                              : "orange-subtle"
                          }
                        >
                          {_.capitalize(store.data_sync_status)}
                        </Badge>
                      </Flex>
                      <Link textDecor={"underline"} href={`https://${store.primary_shop_url}`} target="_blank" className="text-muted-foreground" fontSize={"sm"}>
                        {store.primary_shop_url}
                      </Link>
                    </Box>
                  </Flex>
                  <Flex>
                    <Flex gap={2} alignItems={"center"}>
                        <CoreButton
                        onClick={() => {
                        navigate(`/app/${_organization?.data?.id}/stores/${store.id}`)
                        }}
                        variant={"outline"}
                        leftIcon={<Edit2Icon size={16} />}
                        size={"sm"}
                        >
                        Configure Store
                        </CoreButton>
                        <IconButton
                        onClick={() => {
                        setCurrentStore(store);
                        onDeleteOpen();
                        }}
                        variant={"outline"}
                        colorScheme="red"
                        aria-label=""
                        icon={<Trash2Icon size={16} />}
                        size={"sm"}
                        />
                    </Flex>
                    {/* {profile.data.id !== store.id ? <DropdownMenu>
                        <DropdownMenuTrigger>
                          <IconButton variant={"outline"} size={"xs"} icon={<MoreVerticalIcon size={14} />} aria-label="" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent className="w-52">
                          <DropdownMenuItem
                          onClick={() => {
                            setCurrentStore(store);
                            onOpen();
                          }}>
                          <EditIcon className="mr-2 size-4" />
                          <span>Edit</span></DropdownMenuItem>
                          <DropdownMenuItem 
                          onClick={() => {
                            setCurrentStore(store);
                            onDeleteOpen();
                          }} className="text-red-400 hover:text-red-600 focus:text-red-600">
                          <Trash2Icon className="mr-2 size-4" />
                          <span>Delete</span></DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu> : <></>} */}
                  </Flex>
                </Flex>
              );
            })}
          </Stack>
        </Stack>
      </Page>
    );
  }
  