import {
    SET_STORE_CREATE_LOADING,
    SET_STORE_CREATE_SUCCESS,
    SET_STORE_CREATE_ERROR,
    SET_STORE_LIST_LOADING,
    SET_STORE_LIST_SUCCESS,
    SET_STORE_LIST_ERROR,
    SET_STORE_UPDATE_LOADING,
    SET_STORE_UPDATE_SUCCESS,
    SET_STORE_UPDATE_ERROR,
    SET_STORE_DELETE_LOADING,
    SET_STORE_DELETE_SUCCESS,
    SET_STORE_DELETE_ERROR,
    SET_STORE_GET_LOADING,
    SET_STORE_GET_SUCCESS,
    SET_STORE_GET_ERROR
} from './constants';

// Store Create Actions
export function setStoreCreateLoading() {
    return {
        type: SET_STORE_CREATE_LOADING
    }
}

export function setStoreCreateSuccess(payload: any, metadata?: any) {
    return {
        type: SET_STORE_CREATE_SUCCESS,
        payload,
        metadata
    }
}

export function setStoreCreateError(error: any) {
    return {
        type: SET_STORE_CREATE_ERROR,
        error
    }
}

// Store List Actions
export function setStoreListLoading() {
    return {
        type: SET_STORE_LIST_LOADING
    }
}

export function setStoreListSuccess(payload: any, metadata?: any) {
    return {
        type: SET_STORE_LIST_SUCCESS,
        payload,
        metadata
    }
}

export function setStoreListError(error: any) {
    return {
        type: SET_STORE_LIST_ERROR,
        error
    }
}

// Store Update Actions
export function setStoreUpdateLoading() {
    return {
        type: SET_STORE_UPDATE_LOADING
    }
}

export function setStoreUpdateSuccess(payload: any, metadata?: any) {
    return {
        type: SET_STORE_UPDATE_SUCCESS,
        payload,
        metadata
    }
}

export function setStoreUpdateError(error: any) {
    return {
        type: SET_STORE_UPDATE_ERROR,
        error
    }
}

// Store Delete Actions
export function setStoreDeleteLoading() {
    return {
        type: SET_STORE_DELETE_LOADING
    }
}

export function setStoreDeleteSuccess(payload: any, metadata?: any) {
    return {
        type: SET_STORE_DELETE_SUCCESS,
        payload,
        metadata
    }
}

export function setStoreDeleteError(error: any) {
    return {
        type: SET_STORE_DELETE_ERROR,
        error
    }
}

// Store Get Actions
export function setStoreGetLoading() {
    return {
        type: SET_STORE_GET_LOADING
    }
}

export function setStoreGetSuccess(payload: any, metadata?: any) {
    return {
        type: SET_STORE_GET_SUCCESS,
        payload,
        metadata
    }
}

export function setStoreGetError(error: any) {
    return {
        type: SET_STORE_GET_ERROR,
        error
    }
}
