import {
  Box,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Spinner,
  Stack,
  Step,
  StepIcon,
  StepIndicator,
  Stepper,
  StepStatus,
  StepTitle,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useSteps,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { ArrowRightIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "~/components/ui/button";
import { Textarea } from "~/components/ui/textarea";
import { listOrganizations } from "~/lib/app/organization/thunk";
import { loadUserProfile } from "~/lib/app/platform/thunk";
import { addStore } from "~/lib/app/store/thunk";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";

export default function ShopifyConnect() {
  const [step, setStep] = useState(1);
  const organizations = useAppSelector((state) => state.app.organizations.list);
  const profile = useAppSelector((state) => state.app.platform.profile);
  const create = useAppSelector((state) => state.app.store.create)
  const dispatch = useAppDispatch();
  const [selectedOrg, setSelectedOrg] = useState<string | null>();
  const { activeStep, setActiveStep } = useSteps({
    index: 2,
    count: 4,
  });
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const steps = [
    {
      title: "Fetching store information",
    },
    {
      title: "Generating prompts",
    },
    {
      title: "Training store policy",
    },
    {
      title: "Training store products",
    },
    {
      title: "Training blog articles",
    },
  ];

  useEffect(() => {
    if (profile.state === "success" && profile.data.id) {
      dispatch(listOrganizations(profile.data.id));
    }
  }, [profile]);

  useEffect(() => {
    dispatch(loadUserProfile());
  }, []);

  useEffect(() => {
    if (organizations.data?.length) {
      setSelectedOrg(organizations.data[0].id);
    }
  }, [organizations]);

  return (
    <Center height={"100vh"}>
      {step === 1 ? (
        <Stack maxW={480} gap={4}>
          <Heading as={"h4"} size={"lg"} variant={"h4"}>
            Create Store
          </Heading>
          <Formik
            initialValues={{
              name: "",
              description: "",
              primary_shop_url: searchParams.get("primary_url"),
              namespace: searchParams.get("namespace"),
              provider_id: searchParams.get("store"),
              provider_key: searchParams.get("token")
            }}
            onSubmit={(values) => {
              dispatch(
                addStore(
                  selectedOrg!,
                  {
                    name: values.name,
                    description: values.description,
                    primary_shop_url: values.namespace,
                    provider_id: values.provider_id,
                    provider_key: values.provider_key,
                    store_type: "SHOPIFY",
                    domains: Array.from(
                      (new Set([
                        values.primary_shop_url,
                        values.namespace
                    ].filter(Boolean))).values()
                    )
                  },
                  (data) => {
                    navigate(`/app/${selectedOrg}/stores`)
                  }
                )
              );
            }}
          >
            {({ errors, touched, values, setFieldValue }) => {
              return (
                <Form>
                  <Stack gap={4} maxW={"100%"} mt={4}>
                    <FormControl isInvalid={!!errors.name && touched.name}>
                      <FormLabel fontSize={"sm"}>Name of your store</FormLabel>
                      <Field
                        value={values.name}
                        as={Input}
                        size={"sm"}
                        validate={(value: any) => {
                          let error;
                          if (!value) {
                            error = "Name of store is required";
                          }
                          return error;
                        }}
                        name="name"
                        resize={"none"}
                        borderRadius={8}
                        placeholder="Enter your store name"
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={!!errors.description && touched.description}>
                        <FormLabel fontSize={"sm"}>Description of your store</FormLabel>
                        <Field
                            value={values.description}
                            as={Textarea}
                            size={"sm"}
                            validate={(value: any) => {
                                let error;
                                return error;
                            }}
                            name="description"
                            resize={"none"}
                            borderRadius={8}
                            placeholder="Enter your store description"
                      />
                      <FormErrorMessage>{errors.description}</FormErrorMessage>
                    </FormControl>
                    <Box>
                    <FormLabel fontSize={"sm"} mb={2}>Choose Organization</FormLabel>
                    <Stack  maxW={480} gap={4}>
                        {organizations.data.map((org: any) => {
                            return (
                            <Flex
                            maxW={480}
                                onClick={() => {
                                setSelectedOrg(org.id);
                                }}
                                cursor={"pointer"}
                                _hover={{
                                transform: "scale(1.05)",
                                }}
                                transition={"all 0.3s"}
                                background={
                                selectedOrg === org.id ? "#efecf9" : undefined
                                }
                                w={"40vw"}
                                p={4}
                                py={2}
                                rounded={6}
                                minH={10}
                                border={"1px solid #e4e4e4"}
                                gap={4}
                                alignItems={"center"}
                            >
                                <Checkbox
                                colorScheme="primary"
                                variant={"circular"}
                                isChecked={selectedOrg === org.id}
                                size={"lg"}
                                />
                                <Box>
                                <Text className="capitalize text-semibold text-20">
                                    {org?.name}
                                </Text>
                                <Text className="text-muted-foreground text-sm font-semibold">
                                    {org.teams} teams, {org.agents} agents
                                </Text>
                                </Box>
                            </Flex>
                            );
                        })}
                    </Stack>
                    </Box>
                    <Flex justifyContent={"end"}>
                      <Button
                        type="submit"
                        isLoading={create.state === "loading" || organizations.state === "loading"}
                      >
                        Proceed <ArrowRightIcon size={16} />
                      </Button>
                    </Flex>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Stack>
      ) : (
        <Stack>
          <Flex>
            <Stepper orientation="vertical" index={activeStep}>
              {steps.map((value, index) => (
                <Step
                  style={{
                    alignItems: "center",
                  }}
                  key={index}
                >
                  <StepIndicator
                    background={"transparent !important"}
                    border={0}
                  >
                    <StepStatus
                      complete={
                        <Checkbox
                          size={"lg"}
                          colorScheme="green"
                          readOnly
                          isChecked
                          variant={"circular"}
                        />
                      }
                      incomplete={
                        <Checkbox
                          disabled
                          size={"lg"}
                          readOnly
                          isChecked
                          variant={"circular"}
                        />
                      }
                      active={<Spinner size={"sm"} />}
                    />
                  </StepIndicator>

                  <Box flexShrink="0">
                    <StepTitle>{value.title}</StepTitle>
                  </Box>
                </Step>
              ))}
            </Stepper>
          </Flex>
        </Stack>
      )}
    </Center>
  );
}
