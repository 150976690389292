import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ChevronsUpDownIcon, RotateCcwIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getSpecificStore, updateStore } from "~/lib/app/store/thunk";
import { useAppDispatch, useAppSelector } from "~/lib/hooks";
import _ from "lodash";
import { AsyncSelect, chakraComponents, CreatableSelect } from "chakra-react-select";
import { Field, Form, Formik } from "formik";
import { loadAgents, searchAgents } from "~/lib/app/platform/thunk";

const dropdownStyles: any = {
    dropdownIndicator: (b: any) => ({
      ...b,
      backgroundColor: "transparent",
      borderColor: "transparent",
      border: 0,
    }),
    control: (b: any) => ({
      ...b,
      borderRadius: 8,
    }),
    menuList: (b: any) => ({
      ...b,
      borderRadius: 8,
    }),
  };
  
  const dropdownComponents = {
    DropdownIndicator: (props: any) => (
      <chakraComponents.DropdownIndicator {...props}>
        <ChevronsUpDownIcon size={12} />
      </chakraComponents.DropdownIndicator>
    ),
  };

export default function StoreDetail() {
  const _store = useAppSelector((state) => state.app.store.get.data);
  const updatedStore = useAppSelector((state) => state.app.store.update);
  const assistants = useAppSelector((state) => state.app.platform.agents.list)
  const dispatch = useAppDispatch();
  const ref = useRef<any>();
  const { organization, store } = useParams();
  const [isDirty, setIsDirty] = useState(false)
  const formRef = useRef<any>()
  const toast = useToast()
  const [baseColor, setBaseColor] = useState<string | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    if (organization && store) {
      dispatch(getSpecificStore(organization, store));
       dispatch(loadAgents(organization!, 1, 25, '', {}))
    }
  }, [organization, store]);
  
  const iframeDebouncer = _.debounce(() => {

    ref.current.srcdoc = `<html>
    <head>
    <link href="https://cdn.jsdelivr.net/npm/@electricspeech/esl-chatbot-widget@0.0.21/dist/index.css" rel="stylesheet" type="text/css"></link>
    </head>
    <body>

    <script tag="esl" src="https://cdn.jsdelivr.net/npm/@electricspeech/esl-chatbot-widget@0.0.21/dist/index.js?readOnly=true&storeUrl=${_store.primary_shop_url}${baseColor ? `&baseColor=${encodeURIComponent(baseColor)}` : ''}"></script>
    </body>
 </html>`;
  }, 1000)

  useEffect(() => {
    if (ref && _store?.id) {
        iframeDebouncer()
    }
  }, [ref, _store, baseColor]);

  useEffect(() => {
    setBaseColor(_store.store_config?.baseColor)
  }, [_store])

  return (
    <Stack gap={0} h={"100vh"} overflow={"hidden"}>
      <Flex
        p={2}
        alignItems={"center"}
        h={"64px"}
        borderBottom={"1px solid #e4e4e4"}
      >
        <Flex flex={1} gap={4} alignItems={"center"}>
          <IconButton
            variant={"none"}
            aria-label=""
            onClick={() => {
                navigate(-1)
            }}
            icon={<ArrowLeftIcon size={20} />}
          />
          <Box>
            <Text fontWeight={600} fontSize={"lg"} textTransform={"capitalize"}>
              {_store?.name}
            </Text>
            <Text fontSize={"sm"} color={"gray"}>
              {_store?.primary_shop_url}
            </Text>
          </Box>
        </Flex>
        <Button
          onClick={() => {
            if (formRef.current) {
                formRef.current.handleSubmit()
            }
          }}
          isLoading={updatedStore.state === "loading"}
          type="submit"
          size={"sm"}
          isDisabled={!isDirty}
          variant={"solid"}
          colorScheme="black"
          bg={"black"}
          leftIcon={<RotateCcwIcon size={20} />}
        >
          Publish Changes
        </Button>
      </Flex>
      <Flex w={"100%"} h={"calc(100vh - 64px)"} overflow={"hidden"}>
        <Stack overflow={"scroll"} h={"calc(100vh-64)"} flex={0.65}>
          <Tabs size={"sm"} colorScheme="primary" isLazy variant={"unstyled"}>
            <TabList h={10}>
              <Tab fontWeight={500}>Basic Information</Tab>
              <Tab fontWeight={500}>Theme Configuration</Tab>
              <Tab fontWeight={500}>Knowledge Base</Tab>
            </TabList>

            <TabIndicator
              mt="-1.5px"
              height="4px"
              bg={`hsl(var(--primary))`}
              borderRadius="4px"
            />
            <TabPanels p={2}>
              <TabPanel>
                <Formik
                    innerRef={formRef}
                    enableReinitialize
                    onSubmit={(values) => {
                        dispatch(updateStore(organization!, store!, {
                            agent_id: values.agentId,
                            name: values.name,
                            primary_shop_url: _store.primary_shop_url,
                            domains: values.domains,
                            store_config: {
                                ...(_store.store_config || {}),
                                baseColor: values.baseColor
                            }
                        }, () => {
                            iframeDebouncer()
                            toast({
                                title: "Store Updated successfully",
                                description: "Please refresh your store page to see latest changes"
                            })
                        }))
                    }}
                  initialValues={{
                    agentId: _store.agent?.id,
                    agentName: _store.agent?.name,
                    name: _store.name,
                    description: _store.description,
                    domains: _store.domains || [],
                    baseColor: _store.store_config?.baseColor
                  }}
                >
                  {({ errors, values, setFieldValue, touched }) => {
                    return (
                      <Form>
                        <Stack gap={3}>
                        <Grid columnGap={3} row={3} gridTemplateColumns={`repeat(2, 1fr)`}>
                        <FormControl isInvalid={!!errors.name}>
                            <FormLabel fontSize={"sm"}>Store Name</FormLabel>
                            <Field
                                name="name"
                                value={values.name}
                                as={Input}
                                onChange={(v: string) => {
                                    // @ts-ignore
                                    setFieldValue("name", v.target.value)
                                    setIsDirty(true);
                                }}
                                rounded={8}
                                size="sm"
                                validate={(v: string) => {
                                    let error;
                                    if (!v) error = "Enter store name";
                                    return error;
                                }}
                                fontSize={"sm"}
                                placeholder="Store Name"
                            />
                            <FormErrorMessage>{errors.name as string}</FormErrorMessage>
                        </FormControl>

                        <FormControl>
                          <FormLabel fontSize={"sm"}>Agent</FormLabel>
                          <AsyncSelect
                                value={{
                                    label: values.agentName,
                                    value: values.agentId,
                                }}
                                loadOptions={(input: string) => {
                                    return searchAgents(organization!, 1, 25, input);
                                }}
                                onChange={(v: any) => {
                                    setFieldValue("agentName", v?.label);
                                    setFieldValue("agentId", v?.value);
                                    setIsDirty(true);
                                }}
                                defaultOptions={assistants.data.map((agent: any) => ({
                                    "value": agent.id,
                                    "label": agent.name
                                }))}
                                placeholder={"Attach agent"}
                                selectedOptionColorScheme="primary"
                                size={"sm"}
                                variant={"outline"}
                                chakraStyles={dropdownStyles}
                                components={dropdownComponents}
                            />
                        </FormControl>
                        </Grid>
                        <FormControl>
                        <FormLabel fontSize={"sm"}>Domains</FormLabel>
                        <CreatableSelect
                            isMulti
                                value={values.domains.map((d: any) => ({
                                    label: d,
                                    value: d,
                                    isDisabled: d === _store.primary_shop_url
                                }))}
                                onChange={(v: any) => {
                                    setFieldValue("domains", v.map((d: any) => d.value));
                                    setIsDirty(true);
                                }}
                                
                                placeholder={"Set site domains"}
                                selectedOptionColorScheme="primary"
                                size={"sm"}
                                variant={"outline"}
                                chakraStyles={dropdownStyles}
                                components={dropdownComponents}
                            />
                        </FormControl>
                        <FormControl>
                        <FormLabel fontSize={"sm"}>Choose Base Color</FormLabel>
                            <Flex gap={3} alignItems={"center"}>
                                <Text fontSize={"sm"}>{baseColor}</Text>
                            <Field
                                name="baseColor"
                                value={values.baseColor}
                                as={Input}
                                type="color"
                                onChange={(v: string) => {
                                    // @ts-ignore
                                    setFieldValue("baseColor", v.target.value)
                                    setIsDirty(true);
                                    // @ts-ignore
                                    setBaseColor(v.target.value)
                                }}
                                rounded={8}
                                size="sm"
                                validate={(v: string) => {
                                    let error;
                                    if (!v) error = "Enter store name";
                                    return error;
                                }}
                                fontSize={"sm"}
                                placeholder="Store Name"
                            />
                            </Flex>
                            </FormControl>
                        </Stack>
                      </Form>
                    );
                  }}
                </Formik>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Stack>
        <Stack
          h={"calc(100vh - 64px)"}
          borderLeft={"1px solid #e4e4e4"}
          flex={0.35}
        >
          <iframe height={"100%"} ref={ref} title="Chat Widget" />
        </Stack>
      </Flex>
    </Stack>
  );
}
